@tailwind base;
@tailwind components;
@tailwind utilities;

/** HSL formats **/
@layer base {
  :root {
    --background: 40 20% 97.1%; /* clay orange */
    --foreground: 228, 20%, 9.804%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 13.894, 100%, 49.991%;
    --primary-foreground: 13.894, 100%, 49.991%;

    --secondary: 39.928, 99.997%, 50.182%;
    --secondary-foreground: 39.928, 99.997%, 50.182%;

    --muted: 0 0 89;
    --muted-foreground: 228, 20%, 9.804%;

    --accent: 60, 3%, 88%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 217.7, 13.759%, 9.7894%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground bg-clay-texture;
  }
  .dark body {
    background-image: none; /* remove texture in dark mode */
  }

  /* :focus-visible {
    @apply ring-primary outline-none ring-2;
  } */
}

/** containers that do not want a max width **/
@layer components {
  .container-alt {
    @apply mx-auto px-4 py-12 sm:px-8 md:py-24;
  }
}

@layer utilities {
  .clip-box {
    clip-path: polygon(
      0% 15%,
      0 0,
      15% 0%,
      85% 0%,
      100% 0,
      100% 15%,
      100% 76%,
      76% 76%,
      76% 100%,
      15% 100%,
      0 100%,
      0% 85%
    );
  }
  .bg-clay-texture {
    background-image: url('/texture/clay_tile.jpeg');
    background-size: 50px 50px;
    background-repeat: repeat;
  }

  .bg-higher {
    background-image: url('/higher-bg.png');
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
  }

  .dark .bg-clay-texture {
    background-image: none;
  }
}
